import React, { useState } from "react";
import Page from "../Page";
import { graphql } from "gatsby";
import BlogCard from "./BlogCard";

export default function Index({ data }) {
    const { posts } = data.blog;
    const [currentIndex, setCurrentIndex] = useState(1);
    const elementPerPage = 6;

    // Logic for displaying current posts
    const indexOfLastElement = currentIndex * elementPerPage;
    const currentElements = posts.slice(0, indexOfLastElement);


    function loadMore() {
        setCurrentIndex(currentIndex + 1);
    }

    const category = posts[0].frontmatter.category;

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
            <Page title={capitalizeFirstLetter(category) +" - Naturlabbet"}>
                <section className="container max-w-screen-xl px-5 py-6 mx-auto ">
                    <br />
                    <h1 className="font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight mb-8">{capitalizeFirstLetter(category)}</h1>
                    <div className="blog-grid ">
                        {currentElements && currentElements.map((post) => {
                            return <BlogCard key={post.id} title={post.frontmatter.title} category={post.frontmatter.category} description={post.excerpt} link={"/" + post.frontmatter.category + post.fields.slug} image={post.frontmatter?.coverImage} />
                        })}
                    </div>
                    {currentIndex * elementPerPage < posts.length && (
                        <div className="flex flex-row justify-center items-center mt-4 mb-4">
                            <button className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-5 rounded-full" onClick={loadMore}>
                                Ladda fler{" "}
                            </button>
                        </div>
                    )}
                </section>
            </Page>
        </>
    );
}

export const pageQuery = graphql`
  query Category($category: String!) {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { 
        fileAbsolutePath: { regex: "/(/blog/)/" } 
        frontmatter: { category: { eq: $category } }
        }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          category
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    }
`;
