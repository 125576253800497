import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function BlogCard({
  image,
  link,
  title,
  description,
  date,
  category = 'Utrustning',
}) 
{

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //Replace ö,ä,å with o,a,a for link
  const linkTo = link.replace(/ö/g, 'o').replace(/ä/g, 'a').replace(/å/g, 'a').replace(/ /g, '-').toLowerCase().toLowerCase()


  return (
    <article className="bg-white rounded-lg border border-gray-200 drop-shadow-sm ">
      <a href={linkTo} rel="nofollow">
        <GatsbyImage
          image={image?.childImageSharp.gatsbyImageData}
          className="blogCardImage rounded-t-lg"
        />
      </a>
      <div className="p-6">
        {category && (
          <div className="flex justify-between items-center mb-5 text-gray-500">
            <span className="bg-lime-100 text-lime-900 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ">
              <svg
                className="mr-1 w-3 h-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                  clip-rule="evenodd"
                ></path>
                <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path>
              </svg>
              {capitalizeFirstLetter(category)}
            </span>
          </div>
        )}

        <h3 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          <a href={linkTo} rel="nofollow">
            {title}
          </a>
        </h3>
        <p className="mb-5 font-light text-gray-500">
          {description?.length > 155
            ? description.substring(0, 155) + '...'
            : description}
        </p>
        <div className="flex justify-between items-center">
          <a
            href={linkTo}
            className="inline-flex items-center font-medium text-lime-700  hover:underline"
            rel="nofollow"
          >
            Läs mer
            <svg
              className="ml-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </article>
  );
}
